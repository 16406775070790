<template>
  <div>
    <h3>Report Capaian Kinerja Urusan</h3>
    <div class="row mb-2">
      <div class="col-md-12" style="text-align: right">
        <CButton
          @click="exportPDF()"
          class="mr-2"
          color="danger"
          square
        >
          Export PDF
        </CButton>
      </div>
    </div>
    <CCard id="pdf-content">
      <CCardBody>
        <div class="row">
          <div class="col-12">
            <div v-for="item in items" :key="item.description">
                <b>{{ item.description }}</b><br /><br />
                <div v-for="detail in item.sub_urusan" :key="detail.description">
                  <div class="row mb-2" >
                    <div class="col"><b>{{ detail.code }} {{ detail.description }}</b></div>
                  </div>
                  <div>
                    <div class="table-responsive">
                      <table class="table table-bordered myTable" style="white-space: normal !important">
                        <thead>
                        <tr>
                          <td >No.</td>
                          <td >Indikator</td>
                          <td v-for="tahun in tahuns" :key="tahun.tahun">
                            {{ tahun.tahun }}
                          </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="urusan_detail in detail.sub_urusan" :key="urusan_detail.description">
                          <td  style="width: 50px">{{ urusan_detail.code }}</td>
                          <td  style="width: 300px">{{ urusan_detail.description }}</td>
                          <td v-for="tahun in tahuns" :key="tahun.tahun">
                            {{ getValue(tahun.tahun, urusan_detail.id) }}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <br />
                    </div>
                  </div> 
                </div>
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  data() {
    return {
      items: [],
      tahuns: [],
      urusan_tahuns: [],
      report: [],
      params: {
        sorttype: "ASC",
        sortby: "code",
        row: 5,
        page: 1,
        role_id: 0,
        keyword: "",
      },
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    getData() {
      var loading = this.$loading.show();
      this.$store
        .dispatch("urusan/getUrusanNested", this.params)
        .then((resp) => {
          this.items = resp;
          this.getTahun();
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    getTahun() {
      var loading = this.$loading.show();
      if (this.user.role_id != null) {
        this.params.role_id = this.user.role_id;
      }
      this.$store
        .dispatch("tahun/getTahun", {
          sorttype: "ASC",
          sortby: "tahun",
          row: 100,
          page: 1,
          role_id: 0,
          keyword: "",
      })
        .then((resp) => {
          this.tahuns = resp.data.data;
          this.getReport();
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    getReport() {
      var loading = this.$loading.show();
      if (this.user.role_id != null) {
        this.params.role_id = this.user.role_id;
      }
      this.$store
        .dispatch("urusan/getReport", {})
        .then((resp) => {
          this.report = resp;
          // $(".myTable").DataTable({
          //    scrollY:        "300px",
          //   scrollX:        true,
          //   scrollCollapse: true,
          //   paging:         false,
          //   fixedColumns:   {
          //       left: 2
          //   }
          // });
          loading.hide();
        })
        .catch((e) => {
          this.$toast.error(e);
          loading.hide();
        });
    },
    getValue(tahun, id) {
      let value = 0;
      let value_string = '';
      let is_template = false;
      this.report.forEach(element => {
        if (element.template == 'TEMPLATE_SUDAH_BELUM' || element.template == 'TEMPLATE_YA_TIDAK') { 
console.log(element.template)
        }
        
        if (element.tahun == tahun && id == element.urusan_id) {
          
          if (element.template == 'TEMPLATE_SUDAH_BELUM' || element.template == 'TEMPLATE_YA_TIDAK') {
            is_template = true;
            value_string = element.result;
          } else {
            value = element.result;
          }
          
        }
      });
      if (is_template) {
        return value_string;
      } else {
        return value.toFixed(2);
      }
    },
    exportPDF(id) {
      var opt = {
        margin: 2,
        filename: "Laporan_uraian.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "cm", format: "a4", orientation: "p" },
        pagebreak: { avoid: "tr" },
      };
      var element = document.getElementById("pdf-content");
      html2pdf()
        .set(opt)
        .from(element)
        .save()
        .then(() => console.log("download success !"));
    },
  },
  computed: {
  },
  mounted() {
    this.getData()
  },
};
</script>

